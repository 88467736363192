.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.text-content {
  max-width: 50%;
}

.lottie-container {
  max-width: 50%;
}

.lottie-container-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-height: 100vh; /* Add min-height */
  z-index: -1;
  overflow: hidden;
  opacity: 0.1;
}

.example-container{
  background-color: #333;
  padding-left: 10px;
  padding-bottom: 10px;
  padding-top: 1px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0rem 2rem 2rem 2rem;
  gap: 2rem;
}

.text-content {
  text-align: center;
}

.epg-example {
  background-color: rgba(109, 109, 109, 0.33);
  border-radius: 8px;
  padding: 1rem;
  margin-top: 1rem;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.lottie-container,
.lottie-container-bg {
  width: 100%;
}

@media (min-width: 768px) {
  .container {
    flex-direction: row;
    justify-content: center;
  }

  .text-content {
    text-align: left;
  }

  .lottie-container {
    align-self: flex-start;
  }
}

@media (max-width: 768px) {
  .text-content {
    max-width: 100%;
  }
}

.lottie-container {
  position: relative;
  z-index: 1;
}

@media (max-width: 768px) {
  .lottie-container {
    display: none;
  }
  
  .text-content {
    max-width: 100%;
    position: relative;
    z-index: 2;
  }

}