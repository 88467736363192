.news-article {
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .news-title {
    font-size: 24px;
    margin: 0 0 10px;
  }
  
  .news-meta {
    color: #666;
    font-size: 14px;
    margin: 0;
  }
  
  .news-body {
    font-size: 16px;
    line-height: 1.5;
  }
  

  .patch-notes {
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .patch-title {
    font-size: 24px;
    margin: 0 0 20px;
  }
  
  .patch-version-title {
    font-size: 20px;
    margin: 0 0 10px;
  }
  
  .patch-version-changes {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .patch-version-changes li {
    font-size: 16px;
    line-height: 1.5;
    margin: 0 0 10px;
    padding: 0;
  }
  
  .account-information {
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .account-title {
    font-size: 24px;
    margin: 0 0 20px;
  }
  
  .account-details {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .account-detail {
    font-size: 16px;
    line-height: 1.5;
    margin: 0 0 10px;
    padding: 0;
  }
  
  .account-label {
    display: inline-block;
    font-weight: bold;
    margin-right: 10px;
  }
  
  .account-value {
    display: inline-block;
    font-weight: normal;
  }
  
  .subscription-information {
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .subscription-title {
    font-size: 24px;
    margin: 0 0 20px;
  }
  
  .subscription-details {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .subscription-detail {
    font-size: 16px;
    line-height: 1.5;
    margin: 0 0 10px;
    padding: 0;
  }
  
  .subscription-label {
    display: inline-block;
    font-weight: bold;
    margin-right: 10px;
  }

  .support-contact {
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .support-title {
    font-size: 24px;
    margin: 0 0 20px;
  }
  
  .support-details {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .support-detail {
    font-size: 16px;
    line-height: 1.5;
    margin: 0 0 10px;
    padding: 0;
  }
  
  .support-label {
    display: inline-block;
    font-weight: bold;
    margin-right: 10px;
  }
  
  .support-value {
    display: inline-block;
    font-weight: normal;
  }
  
  
  .subscription-value {
    display: inline-block;
    font-weight: normal;
  }
  
  .cancelled {
    color: red;
  }
  
  .manage-subscription-btn {
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    line-height: 1.5;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    transition: background-color 0.3s ease-in-out;
  }
  
  .manage-subscription-btn:hover {
    background-color: #0062cc;
  }
  