.burger-menu {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1001;
}

.burger-menu > div {
  cursor: pointer;
  font-size: 24px;
}

.menu {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.menu a {
  text-decoration: none;
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  margin: 16px;
  padding: 8px 16px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.menu a:hover {
  background-color: rgba(255, 255, 255, 0.1);
}



@media (max-width: 768px) {
  .burger {
    display: flex;
  }
}

.burger-menu {
  display: none;
}

.menu {
  display: flex;
  flex-direction: column;
}

.noscroll {
  overflow: hidden;
  height: 100%;
}


/* Add this media query */
@media (max-width: 768px) {
  .burger-menu {
    display: block;
  }

  .menu-close {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 24px;
    cursor: pointer;
    z-index: 1001;
  }
}

.css-hyum1k-MuiToolbar-root {
  height: 90px !important;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #191F2A;
  padding: 0.5rem 2rem;
  font-family: Arial, sans-serif;
}

.navbar .logo {
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold;
  text-decoration: none;
}

.navbar .nav-links {
  display: flex;
  gap: 1rem;
}

.navbar .nav-links a {
  color: #fff;
  text-decoration: none;
  padding: 0.25rem 0.5rem;
  font-size: 1.1rem;
}

.navbar .nav-links a:hover {
  background-color: #191F2A;
  border-radius: 3px;
}

@media (max-width: 768px) {
  .css-hyum1k-MuiToolbar-root {
    height: 64px !important;
  }

  .menu-toggle {
    top: 1rem;
  }
}

